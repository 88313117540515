import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import NewLayoutEn from '../../../components/newsLayoutEn.js';
export const _frontmatter = {
  "title": "GlobalTel has become one of VMWare partners in the field of modern applications",
  "date": "2022-31-05",
  "categories": "en",
  "description": "GlobalTel has once again confirmed its technical readiness and maturity. It has become a VMWare partner in the field of modern applications from Dell.",
  "key": "en",
  "preview": "GlobalTel has once again confirmed its technical readiness and maturity. It has become a VMWare partner in the field of modern applications from Dell."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = NewLayoutEn;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>GlobalTel has become one of VMWare partners in the field of modern applications</h1>
    <p>
    GlobalTel has once again confirmed its technical readiness and maturity. It has become a VMWare partner in the field of modern applications from Dell. <a href="../about-us/#certificates" target="blank">globaltel.sk/en/about-us/certificates</a>
    </p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      